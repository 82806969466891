/* :root {
  --primary-color: #AF8855 ;
  --secondary-color: 000000;
} */

:root {
  /* 1st color */
  /* --primary-color: #4dc0cf;
  --primary-color-rgb: rgba(77, 192, 207, 0.7);

  --secondary-color: #000000;
  --secondary-color-rgb: rgba(250, 250, 250, 0.7); */

  /* 2nd color */
  /* --primary-color:  #704240;
  --primary-color-rgb: rgba(113, 68, 65,0.7);

  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */

  /* 3rd color */
  /* --primary-color:  #4682B4;
  --primary-color-rgb: rgba(69, 131, 181, 0.7);

  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */

  /* 4th color */
  --primary-color: #556b2f;
  --primary-color-rgb: rgba(85, 107, 46, 0.7);

  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7);

  /* 5th color */
  /* --primary-color:  #6A5ACD;
  --primary-color-rgb: rgba(106, 91, 205, 0.7)

  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */
}
