/*------------------------------------------*/
/*  HERO-10
/*------------------------------------------*/

#hero-10 {
  position: relative;
  background-image: url(../../../public/images/hero/hero-bg-10.webp);
  padding-top: 200px;
  padding-bottom: 100px;
}

.hero-10-txt h2 {
  font-size: 5rem;
  letter-spacing: -2px;
  margin-bottom: 14px;
}

.hero-10-txt p {
  font-size: 1.25rem;
  padding-right: 20%;
  margin-bottom: 35px;
}

.hero-10-schedule {
  margin-right: 10px;
}

.hero-10-schedule h5 {
  margin-bottom: 20px;
}

.hero-10-schedule p {
  line-height: 1;
  margin-bottom: 12px;
}

.hero-10-schedule p span {
  font-weight: 600;
  margin-left: 15px;
}
